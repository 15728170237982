/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body,
h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    font-family: Poppins, Helvetica Neue, Arial, Helvetica, sans-serif !important;
}

#root {
    height: 100%;
}

body ::-webkit-scrollbar {
    width: 5px !important;
}

.fontSize16px {
    font-size: 16px !important;
}

.fontSize7px {
    font-size: 7px;
}

.fontSize9px {
    font-size: 9px;
}

.fontSize11px {
    font-size: 11px;
}

.fontSize21px,
.fontSize21px.large {
    font-size: 21px !important;
}

.pb-8 {
    padding-bottom: 2rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.py-6{
    padding-top:1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.m-0{
    margin: 0 !important;
}

.text-left{
    text-align: left!important;
}

.mix-blend-darken{
    mix-blend-mode: darken !important;
}

.w-screen{
    width:100vw !important;
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
}

.mx-2{
    margin-left: .5rem !important;
    margin-right: .5rem !important;
}

.mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
}

.w-28 {
    width: 7rem !important;
}

.h-28 {
    height: 7rem !important;
}

.pr-4 {
    padding-right: 1rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pb-36 {
    padding-bottom: 9rem !important;
}

.p-10{
    padding: 2.5rem !important;
}

.mt-8{
    margin-top: 2rem !important;
} 

.mb-4{
    margin-bottom: 1rem !important;
}

.testimonial-slider .slick-track {
    display: flex !important;
}

.testimonial-slider .slick-slide {
    height: inherit !important;
}

.testimonial-slider .slick-slide > div{
    height: 100%;
}

.testimonial-slider .testimonial {
    min-height: 100%;
}

.testimonial-slider .slick-list {
    width: 100vw;
}

.testimonial-slider .slick-list {
    margin: 0 -.5rem;
}

.testimonial-slider .slick-slide>div {
    padding: 0 .5rem;
}

.testimonial-slider .slick-arrow{
    background-color: transparent;
    box-shadow: none !important;
    border: none;
    width: auto;
    height: auto;
    top: -5rem;
}

.testimonial-slider .slick-arrow.slick-disabled::before {
    opacity: .5;
}

.testimonial-slider .slick-next::before {
    transform: rotate(180deg);
    transform-origin: center;
}

.testimonial-slider .slick-next {
    right: 0rem;
}

.testimonial-slider .slick-prev {
    right: 4rem;
}

.testimonial-slider .slick-arrow::before{
    margin: auto;
    content: '';
    width: 3rem;
    height: 3rem;
    display: block;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/img/testimonial-arrow.svg');
}

.faq .ui.tab.active{
    background: none;
    border: none;
    padding: 0;
}

.faq .ui.text.menu{
    margin: 1rem 0 3rem;
    flex-wrap: wrap;
}

.faq .ui.text.menu .item {
    padding: 15px 30px;
    font-weight: 600 !important;
    color: black;
    opacity: .5;
    border: 2px solid transparent;
}

.faq .ui.text.menu .active.item{
    opacity: 1;
    border: 2px solid black;
}

.homePromo::before {
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.7) 80%, rgba(0,0,0,0) 100%);
    content: '';
    width: 50%;
    height: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.ui.accordion:not(.styled) .title ~ .content:not(.ui):last-child {
    padding-bottom: 0 !important;
}

.bgWhite ::placeholder,
.bgWhite .ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
    color: gray !important;
}

::placeholder,
.ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1; /* Firefox */
}

.landingLabel {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.landing ::placeholder,
.landingInput ::placeholder {
    color: gray !important;
    opacity: 1; /* Firefox */
}

.bgLightGray::placeholder {
    color: gray !important;
    opacity: 1; /* Firefox */
}

.ui.crazyFilter {
    padding: 20px !important;
    border: 1px solid transparent !important;
    background: transparent !important;
    border-radius: 0 !important;
}

.ui.selection.active.dropdown.crazyFilter {
    box-shadow: none;
    border: 1px solid #000 !important;
}

.ui.active.visible.crazyFilter {
    border: 1px solid !important;
}

.ui.active.visible.crazyFilter::after {
    content: '';
    display: block;
    border: 1px solid #ebebeb;
    bottom: -2px;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 99;
}

.ui.active.visible.crazyFilter .menu {
    background: #ebebeb;
    border-radius: 0;
    border: 1px solid;
    left: -1px;
    min-width: 250px;
}

.ui.crazyFilter.dropdown > .left.menu {
    right: -1px !important;
}

.moveDropdownToLeft > .ui.crazyFilter.dropdown .menu > .item {
    justify-content: flex-end;
}

.ui.crazyFilter .ui.checkbox label:before {
    top: 19px;
    left: -10px;
}

.ui.crazyFilter .ui.checkbox input:checked ~ label:before,
.ui.crazyFilter .ui.checkbox label:hover::before {
    background: transparent;
}

.ui.crazyFilter .ui.checkbox label:after {
    top: 19px;
    left: -10px;
}

.ui.crazyFilter .menu .item label,
.ui.crazyFilter.dropdown.selected, .ui.crazyFilter.dropdown .menu .selected.item,
.ui.crazyFilter.dropdown .menu > .item {
    font-size: 18px;
    padding: 20px;
    margin-left: 10px;
}

.ui.crazyFilter.dropdown.selected, .ui.crazyFilter.dropdown .menu .selected.item,
.ui.crazyFilter.dropdown .menu > .item{
    margin-left: 0;
}

.ui.crazyFilter .menu .item {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.ui.crazyFilter.crazyFilterThumbnail .menu.transition.visible {
    display: flex !important;
    width: 100%;
    max-width: 512px;
    flex-wrap: wrap;
    flex-direction: row;
}

.ui.crazyFilter.crazyFilterThumbnail .menu .item {
    width: 60px;
    height: 60px;
    margin: 1px !important;
    background: #fff;
}

.crazyFilter.ui.dropdown:not(.button) > .default.text,
.crazyFilter.ui.dropdown > .text {
    color: #000 !important;
    font-size: 18px;
}

.crazyFilter.ui.dropdown > .text {
    text-transform: uppercase;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff !important;
}

.maintainance {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}


button.slick-arrow {
    -webkit-appearance: unset !important;   
}

.slick-slide > div > div.ui.grid {
    outline: none;
}

.mobileMenu {
    min-width: 150px;
    position: absolute;
    height: 100%;
    z-index: 999;
    width: 45vw;
    display: block;
    left: -45vw;
    transition: 0.2s ease;
    overflow-y: auto;
    overflow-x: hidden;
}

.orderDetail {
    outline: none !important;
}

.orderItem {
    display: flex;
    align-items: center;
    text-align: center;
    flex-flow: column;
}

.welcome-background{
    position: relative;
}

.welcome-background::before{
    content: '';
    background: url('/img/bg_welcome_l.jpg');
    background-size: contain;
    display: block;
    position: absolute;
    width: 722px;
    height: 583px;
    bottom: 0;
    left: 24%;
}

.orderItem img {
    width: auto !important;
    max-height: 44px !important;
}

.orderItem > img.cartProduct {
    max-height: 38px !important;    
    align-self: center;
}

.mobileMenu.slide-out {
    left: 0;
}

.mobileMenu .ui.secondary.inverted.menu a.item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.mobileMenu .ui.secondary.inverted.menu a.item:hover {
    border-radius: 0 !important;
}

.noBg {
    background: none !important;
}

.contact-button{
    max-width: 400px;
    line-height: 14px;
    align-items: baseline;
}

/* .welcome-border::before{
    content: '';
    display: block;
    width: 100%;
    margin: -40px;
    margin-top: 0;
    height: 100%;
    border: 40px #00295D solid;
    border-top: none;
    box-sizing: border-box;
    position: absolute;
} */

.cub-logo-container{
    padding: 12px;
    padding-right: 4rem;
}

.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    z-index: 1;
}

.slick-next:before, .slick-prev:before {
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #000;
}

.slick-prev {
    right: 40px;
}

.product .slick-prev {
    right: 25px;
}

.order .slick-prev {
    right: 15px;
}

.slick-prev::before {
    content: "\f053";
    font-family: Icons;
}

.slick-next::before, [dir="rtl"] .slick-prev::before {
    content: "\f054";
    font-family: Icons;
}

.slick-next {
    right: 10px;
}

.product .slick-next {
    right: 0;
}

.order .slick-next {
    right: -15px;
}

.slick-next, .slick-prev {
    position: absolute;
    padding: 0;
    top: -25px;
    border: 1px solid #F3F3F3;
    background-color: #fff;
    box-shadow: 0 0 20px #00000008 !important;
}

.brandListVertical {
    -webkit-column-gap: 3em;
    -moz-column-gap: 3em;
    column-gap: 3em;
    list-style-type: none;
    padding: 0;
}

.brandListVertical li {
    margin-bottom: 20px;
}

.product .slick-next,
.product .slick-prev {
    top: -38px;
}

.order .slick-next,
.order .slick-prev {
    top: -38px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.height100 {
    height: 100% !important;
}

.height146 {
    height: 146px !important;
}

.height250px {
    height: 250px !important;
}

.height100plus60 {
    height: calc(100% + 60px) !important;
}

.height86vh {
    height: 86vh !important;
}

.noMargin {
    margin: 0 !important;
}

.noMarginLeft {
    margin-left: 0 !important;
}

.noMarginTop {
    margin-top: 0 !important;
}

.noMarginBottom {
    margin-bottom: 0 !important;
}

.noMarginRight {
    margin-right: 0 !important;
}

.ml-auto{
    margin-left: auto !important;
}

.my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.margin15 {
    margin: 15px;
}

.margin25 {
    margin: 25px;
}

.marginTop5 {
    margin-top: 5px !important;
}

.marginTop10 {
    margin-top: 10px !important;
}

.marginTop15 {
    margin-top: 15px !important;
}

.marginTop20 {
    margin-top: 20px !important;
}

.marginTop30 {
    margin-top: 30px !important;
}

.marginTop40 {
    margin-top: 40px !important;
}

.marginTop1em {
    margin-top: 1em !important;
}

.marginTop2em {
    margin-top: 2em !important;
}

.marginTop4em {
    margin-top: 4em !important;
}

.marginTop40 {
    margin-top: 40px !important;
}

.marginLeft5 {
    margin-left: 5px !important;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginRight20 {
    margin-right: 20px !important;
}

.marginRight110 {
    margin-right: 110px !important;
}

.marginBottom {
    margin-bottom: 5px !important;
}

.marginBottom10 {
    margin-bottom: 10px !important;
}

.marginBottom20 {
    margin-bottom: 20px !important;
}

.marginBottom40 {
    margin-bottom: 30px !important;
}

.alignLeft {
    text-align: left !important;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center !important;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.center0 {
    margin: 0 auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.center10 {
    margin: 0 auto;
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
}

.center20 {
    margin: 0 auto;
    top: 20px;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
}

.center45p {
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    text-align: center;
}

.ui.icon.button > .icon.center10 {
    margin: 0 auto !important;
}

.white,
.white > label {
    color: #fff !important;
}

.lineHeight30px {
    line-height: 30px !important;
}

.lightGray {
    color: #B7B7B7 !important;
}

.lightRed{
    color: rgb(252 165 165) !important;
}

.gray {
    color: #DDDDDD !important;
}

.darkGray {
    color: #666666 !important;
}

.lightGreen {
    color: #859B1A !important;
}

.link {
    cursor: pointer;
    opacity: 1 !important;
}

.opacity0 {
    opacity: 0 !important;
}

.cursor {
    cursor: pointer;
}

.link:hover {
    color: #6892BE;
}

.fixed {
    position: fixed !important;
}

.absolute {
    position: absolute !important;
}

.order-1{
    order: -1;
}

.relative {
    position: relative;
}

.mobileContainer {
    max-width: 320px;
    margin: 0 auto;
}

.textDecoration {
    text-decoration: underline;
}

.noDecoration {
    text-decoration: none;
}

.mini {
    font-size: 8px !important;
}

.tiny {
    font-size: 9px !important;
}

.small,
.small > input {
    font-size: 10px !important;
}

.menu {
    font-size: 14px !important;
}

.productImageLink {
    height: 100%;
    display: flex;
}

.productImage {
    width: auto;
    max-width: 145px !important;
    max-height: 145px;
    align-self: center;
}

.regular {
    font-size: 14px !important;
}

.large {
    font-size: 16px !important;
}

.largeMedium {
    font-size: 18px !important;
}

.big {
    font-size: 21px !important;
}

.huge {
    font-size: 24px !important;
} 

.normal {
    font-weight: normal !important;
}

.bold {
    font-weight: bold !important;
}

.button {
    border-radius: 15px;
}

.inline {
    display: inline-block !important;
}

.flex {
    display: flex !important;
}

.flexBetween {
    justify-content: space-between;
}

.alignItemsCenter {
    align-items: center;
}

.flexEnd {
    justify-content: flex-end !important;
}

.alignEnd {
    align-items: flex-end !important;
}

.hide {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

button .hidden {
    visibility: inherit;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.left {
    left: 0;
}

.left5 {
    left: 5px;
}

.left10 {
    left: 10px;
}

.left15 {
    left: 15px;
}

.left30 {
    left: 30px;
}

.left35 {
    left: 35px;
}

.left26p {
    left: 26%;
}

.left1em {
    left: 1em;
}

.right {
    right: 0;
}

.right5 {
    right: 5px;
}

.right8 {
    right: 8px;
}

.right10 {
    right: 10px;
}

.right15 {
    right: 15px;
}

.right20 {
    right: 20px;
}

.right20p {
    right: 20%;
}

.right35 {
    right: 35px;
}

.top {
    top: 0;
}

.topMinus5 {
    top: -5px;
}

.top5 {
    top: 5px;
}

.top7 {
    top: 7px;
}

.top10 {
    top: 10px;
}

.top13 {
    top: 13px;
}

.top15 {
    top: 15px;
}

.top20 {
    top: 20px;
}

.top25 {
    top: 25px;
}

.top29 {
    top: 29px;
}

.top30px {
    top: 30px;
}

.top32 {
    top: 32px;
}

.top36px {
    top: 36px;
}

.top45px {
    top: 45px;
}

.topMinus20 {
    top: -20px;
}

.bottomMinus100calc {
    bottom: -100%;
}

.bottom {
    bottom: 0;
}

.bottomMinus5 {
    bottom: -5px;
}

.bottom15 {
    bottom: 15px;
}

.bottom20 {
    bottom: 20px;
}

.bottom25 {
    bottom: 25px;
}

.bottom30 {
    bottom: 30px;
}

.bottom50 {
    bottom: 20px;
}

.bottom60 {
    bottom: 60px;
}

.bottom150 {
    bottom: 150px;
}

.bottom200 {
    bottom: 200px;
}

.labelTopMinus20 {
    margin-top: 30px !important;
}

.labelTopMinus20 .label {
    top: -20px !important;
}

.minHeight30 {
    min-height: 30px;
}

.minHeight40 {
    min-height: 40px !important;
}

.minHeight45 {
    min-height: 45px;
}

.minHeight60 {
    min-height: 60px;
}

.minHeight100 {
    min-height: 100px;
}

.minHeight349px {
    min-height: 349px;
}

.minHeight450 {
    min-height: 450px;
}

.minHeight466px {
    min-height: 466px;
}

.minHeight50vh {
    min-height: 50vh;
}

.maxHeight60 {
    max-height: 60px;
}

.maxHeight210 {
    max-height: 210px;
}

.maxHeight350px {
    max-height: 350px;
}

.maxHeight450 {
    max-height: 450px;
}

.maxHeight50vh {
    max-height: 50vh;
}

.maxHeight75vh {
    max-height: 75vh;
}

.maxHeight100vh {
    max-height: 100vh;
}

.height20 {
    height: 20px;
}

.height24 {
    height: 24px;
}

.height30px {
    height: 30px !important;
}

.height38px {
    height: 38px;
}

.height38px input {
    height: 38px;
}

.height0 {
    height: 0;
}

.height42 {
    height: 42px;
}

.height50 {
    height: 50px;
}

.height80 {
    height: 80px;
}

.height100px {
    height: 100px;
}

.height145px {
    height: 145px;
}

.height100vh {
    height: 100vh;
    position: relative;
}

.height51px {
    height: 51px !important;
}

.height120 {
    height: 120px;
}

.bottom5px {
    bottom: 5px;
}

.noOutline {
    outline: 0 !important;
}

.noBorder,
.noBorder > input {
    border: none !important;
}

.noBorderRadius {
    border-radius: 0 !important;
}

.borderRadius5 {
    border-radius: 5px !important;
}

.borderRadius15 {
    border-radius: 15px !important;
}

.borderRadius23 {
    border-radius: 23px;
}

.borderRadius10,
.borderRadius10 > input {
    border-radius: 10px !important;
}

.borderTopRadius10 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.circle {
    border-radius: 50%;
}

.borderRadiusLeft2 {
    border-radius: 2px 0 0 2px;
}

.borderRadiusLeft5 {
    border-radius: 5px 0 0 5px;
}

.borderRadiusRight2 {
    border-radius: 0 2px 2px 0;
}

.borderRadiusRight5 {
    border-radius: 0 5px 5px 0;
}

.borderY {
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
}

.fitContent {
    width: fit-content !important;
}

.widthAuto {
    width: auto !important;
}

.width100 {
    width: 100% !important;
}

.width100 input{
    width: 100% !important;
}

.width25px {
    width: 25px !important;
}

.width30px {
    width: 30px !important;
}

.width100px {
    width: 100px !important;
}

.width100Minus2rem {
    width: calc(100% - 2rem) !important;
}

.width0 {
    width: 0 !important;
    opacity: 0;
}

.width20p {
    width: 20% !important;
}

.maxWidth15 {
    max-width: 15px !important;
    width: 100%;
}

.maxWidth30 {
    max-width: 30px !important;
    width: 100%;
}

.maxWidth45 {
    max-width: 45.7px !important;
    width: 100%;
}

.maxWidth55 {
    max-width: 55px !important;
    width: 100%;
}

.maxWidth90 {
    max-width: 90px !important;
    width: 100%;
}

.maxWidth150 {
    max-width: 150px !important;
    width: 100%;
}

.maxWidth230 {
    max-width: 240px !important;
    width: 100%;
}

.maxWidth320 {
    max-width: 320px !important;
    width: 100%;
}

.maxWidth525 {
    max-width: 525px !important;
    width: 100%;
}

.maxWidth550 {
    max-width: 550px !important;
    width: 100%;
}

.maxWidth650 {
    max-width: 650px !important;
    width: 100%;
}

.maxWidth70p {
    max-width: 1140px !important;
    width: 100%;
}

.mobilemenu-open {
    overflow: hidden;
}

.black {
    color: #000 !important;
}

.blackBackground {
    content: '';
    background: #000000bd;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.stickySide .ui.fixed.top.sticky{
    height: 100% !important;
    z-index: 999 !important;
}

.overflowHidden {
    overflow: hidden;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: none;
    position: relative;
}

.overflowAuto {
    overflow: auto;
}

.overflowYAuto {
    overflow-y: auto;
}

.overflowXHidden {
    overflow-x: hidden;
}

.paddingDefault {
    padding: 0.78571429rem 1.14285714rem !important;
}

.ui.dropdown .menu > .item.noPadding,
.noPadding {
    padding: 0 !important;
}

.noPaddingLeft {
    padding-left: 0 !important;
}

.noPaddingRight {
    padding-right: 0 !important;
}

.noPaddingTop {
    padding-top: 0 !important;
}

.paddingTop5px {
    padding-top: 5px !important;
}

.paddingTop6px {
    padding-top: 6px !important;
}

.paddingTop1rem {
    padding-top: 1rem !important;
}

.paddingTop15rem {
    padding-top: 1.5rem !important;
}

.paddingBottom5px {
    padding-bottom: 5px !important;
}

.paddingBottom15rem {
    padding-bottom: 1.5rem !important;
}

.noPaddingBottom {
    padding-bottom: 0 !important;
}

.noPaddingRight {
    padding-right: 0 !important;
}

.paddingRight5 {
    padding-right: 5px;
}

.numCart {
    position: absolute;
    top: -5px;
    right: 0;
}

.accountMenu > .menu.transition.visible{
    border-radius: 5px;
    right: -9px;
    margin-top: 15px;
    left: auto;
}

.accountMenu > .menu.transition.visible::before {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    content: '';
    position: absolute;
    top: -5px;
    right: 12px;
}

.whitespace {
    white-space: pre;
}

.noWrap {
    white-space: nowrap;
}

.timeSelect:hover,
.timeSelect.selected {
    border: 1px solid #002F5F !important;
}

.timeSelect:hover .lightGray.mini,
.timeSelect:hover .lightGray.tiny,
.timeSelect.selected .lightGray.mini,
.timeSelect.selected .lightGray.tiny {
    color: #002F5F !important;
    font-weight: 700;
}

.no-quota{
    border: 1px solid rgb(252 165 165) !important;
    background: rgb(254 226 226) !important;
}

.no-quota:hover,
.no-quota.selected {
    border: 1px solid rgb(252 165 165) !important;
}

.no-quota:hover .lightGray.mini,
.no-quota:hover .lightGray.tiny,
.no-quota.selected .lightGray.mini,
.no-quota.selected .lightGray.tiny {
    font-weight: 500;
    color: rgb(252 165 165) !important;
}

.padding5 {
    padding: 5px !important;
}

.padding5-10 {
    padding: 5px 10px !important;
}

.padding10-20 {
    padding: 10px 20px !important;
}

.paddingTopBottom40px {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.padding10 {
    padding: 10px !important;
}

.paddingTime {
    padding: 11px 5px !important;
}

.timeSelectWrapper {
    margin-left: 5px !important;
    min-width: 58px;
}

.paddingLeftRight03rem {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
}

.paddingLeftRight07rem {
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
}

.paddingLeftRight5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.paddingLeftRight1rem {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.padding5 {
    padding: 5px;
}

.padding15 {
    padding: 15px;
}

.padding25 {
    padding: 25px;
}

.padding30 {
    padding: 30px !important;
}

.padding40 {
    padding: 30px 40px !important;
}

.padding45 {
    padding: 45px !important;
}

.paddingTop2 {
    padding-top: 2px;
}

.paddingTop5 {
    padding-top: 3px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingTop30 {
    padding-top: 30px;
}

.paddingTopBottom10 > input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.paddingBottom2 {
    padding-bottom: 2px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.paddingBottom1em {
    padding-bottom: 1em !important;
}

.paddingRight20 {
    padding-right: 20px !important;
}

.paddingRight30 {
    padding-right: 30px !important;
}

.paddingRight2em {
    padding-right: 2em !important;
}

.paddingRight4em {
    padding-right: 4em !important;
}

.paddingLeft30 {
    padding-left: 30px !important;
}

.paddingLeft2em {
    padding-left: 2em !important;
}

.paddingLeft3em {
    padding-left: 3em !important;
}

.paddingLeft4em {
    padding-left: 4em !important;
}

.paddingInput {
    padding: 8px 10px;
}

.noShadow {
    box-shadow: none !important;
}

.shadow20 {
    box-shadow: 0 0 20px #00000008 !important;
}

.alignSelfCenter {
    align-self: center !important;
}

.alignSelfEnd {
    align-self: flex-end !important;
}

.border1 {
    border: 1px solid #EAEAEA;
}

.borderYellow {
    border: 1px solid #F28E1C;
}

.borderBlue {
    border: 1px solid #0C2F5F;
}

.borderWhite {
    border: 1px solid #fff !important;
}

.borderGray {
    border: 1px solid #EAEAEA;
}

.borderLightRed{
    border: 1px solid rgb(252 165 165) !important;
}

.borderPrimary {
    border: 1px solid #002F5F !important;
    box-shadow: transparent !important;
}

.transparent,
button.ui.button.transparent,
button.ui.icon.button.transparent {
    background: transparent !important;
}

.zIndex1 {
    z-index: 1;
}

.zIndex2 {
    z-index: 2;
}

.zIndexMinus999 {
    z-index: -999;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.transition {
    transition: all ease-in-out .2s;
}

.fade-exit-active {
    opacity: 0;
}

.loginBg {
    background-size: cover;
    background-position: 20%;
}

.userProfilePicture {
    border-radius: 50%;
    background-color: #6892BE;
    width: 30px;
    height: 30px;
    position: relative;
    top: -5px;
    color: #fff;
    text-align: center !important;
    padding-top: 6px;
}

.userProfilePictureLarge {
    border-radius: 50%;
    background-color: #6892BE;
    width: 30px;
    height: 30px;
}

.userChatLogo {
    border-radius: 50%;
    padding: 8px 12px;
}

.shadow-none{
    box-shadow: none !important;
}

.headerBg {
    background-color: #002F5F;
    z-index: 999;
}

.headerDarkBg {
    background-color: #00234D;
    z-index: 999;
}

.bgYellow {
    background-color: #F28E1C !important;
}

.bgPrimary {
    background-color: #002F5F !important;
}

.verifiedCheck {
    border: none !important;
    margin: 0 !important;
}

.button.ui.basic.button.primaryColor,
.primaryColor,
.primaryColor a {
    color: #002F5F !important;
}

.marginTopAuto {
    margin-top: auto;
}

.dropzone {
    cursor: pointer;
    border-radius: 10px;
    border: 1px dashed #B1B1B1;
    color: #B1B1B1;
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    height: 73px;
    margin-bottom: 15px;
    width: 100%;
}

.dropzone:focus {
    outline: #F28E1C;
}

.dropzone p {
    margin-top: auto;
    margin-bottom: auto !important;
    text-align: center;
    font-size: 11px;
}

.yellowText {
    color: #F28E1C !important;
}

.blueText {
    color: #0C2F5F !important;
}

.red {
    color: #F00D2D !important;
}

.green {
    color: green !important;
}

.bgSecondary {
    background-color: #6892BE !important;
}

.bgOrange {
    background-color: #F7942D !important;
}

.bgWhite {
    background-color: #fff !important;
}

.bgSecondaryLower {
    background-color: #43688e !important;
}

.ui.label.filterLabel {
    font-size: 14px;
    font-weight: 300;
    background-color: #b7b7b7;
    border-radius: 0;
    padding: 10px 20px;
}

.search.bgSecondaryLower .ui.icon.input {
    width: 100%;
}

.search.bgSecondaryLower .ui.icon.input > input {
    background: none;
    border: none;
}

.search.white .ui.icon.input > input{
    color: #fff;
}

.search.white .ui.icon.input > i.search.icon {
    width: 2.2em !important;
}

.search.white .ui.icon.input > i.search.icon:before {    
    background: url('/img/magnifying-glass.svg') no-repeat !important;
    
    width: 16px;
    height: 16px;
    content: '' !important;
}

.bgLightGray,
.bgLightGray > input {
    background-color: #F5F5F5 !important;
}

.hoverWhiteBackground:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.bgGradientGray {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f3f3+0,f4f4f4+50,f5f5f5+83,f5f5f5+100&1+0,0.79+50,0.46+83,0+100 */
    background: -moz-linear-gradient(top,  rgba(243,243,243,1) 0%, rgba(244,244,244,0.79) 50%, rgba(245,245,245,0.46) 83%, rgba(245,245,245,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(243,243,243,1) 0%,rgba(244,244,244,0.79) 50%,rgba(245,245,245,0.46) 83%,rgba(245,245,245,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(243,243,243,1) 0%,rgba(244,244,244,0.79) 50%,rgba(245,245,245,0.46) 83%,rgba(245,245,245,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#00f5f5f5',GradientType=0 ); /* IE6-9 */
}

.bgGradientGrayReverse {
    background: #f5f5f5;
    background: linear-gradient(0deg, #f5f5f5 0%, #f5f5f59e 50%, rgba(245, 245, 245, 0) 84%);
}

.textCapitalize {
    text-transform: capitalize !important;
}

.textUppercase {
    text-transform: uppercase !important;
}

.bgBlack {
    background-color: #000 !important;
}

.bgBlackOpacity:before {
    content: '';
    background: #000;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 10px;
}

.recentFavourite {
    width: 19px;
    height: 19px;
}

/* random fix for semantic ui */
.ui.selection.dropdown.filterCategory {
    background: #6892BE;
    border-radius: 5px 0 0 5px;
    color: #fff;
}

.ui.padded.grid:not(.vertically):not(.horizontally).bgLogo.marginTop1em {
    margin-top: 1em !important;
}

.ui.padded.grid:not(.vertically):not(.horizontally).bgLogo.marginTop2em {
    margin-top: 2em !important;
}

.ui.padded.grid:not(.vertically):not(.horizontally).bgLogo.marginTop4em {
    margin-top: 4em !important;
}

.ui.page.modals.dimmer.transition.visible.active {
    padding: 0;
}

.modals.dimmer .ui.scrolling.modal {
    margin: 0 !important;
}

.paddingInput i {
    right: 10px !important;
}

.productSlide {
    position: relative;
}

.productColumn {
    transition: all 0.4s;
}

.ui.grid > .row > [class*="four wide computer"].column.productColumn.favouriteMove {
    width: 0 !important;
    opacity: 0;
    z-index: -999;
    padding: 0 !important;
    overflow: hidden;
    white-space: nowrap;
}

.productSliderCustom:before {
    content: '';
    /*border-right: 1px solid #F5F5F5;*/
    height: 100%;
    position: absolute;
    right: 0;
}

.productSliderCustom {
    outline: none !important;
}

.addToCartMiniContainer,
.addToCartMini,
.paymentSelect,
.normalTransition,
.pastCartDetails {
    transition: 0.4s ease all !important;
}

.deliveryTime {
    transition: 0.3s ease all !important;
}

.paymentSelect,
.heightAuto {
    height: auto;
}

.ui.container{
    padding: 0;
}

.cub-yellow{
    color: #F28E1C !important;
}

.addToCartMiniContainer,
.addToCartMini {
    width: 30px;
    height: 30px;
    z-index: 1;
    padding: 0 !important;
}

.filter .title {
    font-size: 18px !important;
    color: #002F5F !important;
    text-transform: uppercase;
    position: relative;
}

.filter label {
    color: #666666 !important;
    font-size: 18px !important;
    padding-left: 30px !important;
    padding-bottom: 10px !important;
}

.filter .title i {
    position: absolute;
    right: 0;
}

.ui.checkbox .box:before, .ui.checkbox label:before {
    border-radius: 0;
    width: 19px;
    height: 19px;
}

.ui.checkbox .box:after, .ui.checkbox label:after {
    width: 19px;
    height: 19px;
}

.disabled {
    opacity: 0.5;
}

.applyFilter {
    width: calc(100% - 3em) !important;
    height: 31px;
}

.filter .ui.checked.checkbox label {
    color: #002F5F !important;
    font-weight: bold;
}

.ui.checkbox.myCheckbox label {
    font-size: 16px;
}

.ui.checkbox.myCheckbox label:before {
    background: transparent;
    border: 1px solid gray;
    width: 17px;
    height: 17px;
    top: 0;
}

.ui.checkbox.myCheckbox input:checked ~ label:before {
    background: transparent;
    border: 1px solid gray;
}

.ui.checkbox.myCheckbox input:checked ~ label:after{
    font-size: 16px;
    width: 17px;
    height: 17px;
    color: gray;
}

.ui.checkbox.myCheckbox label:hover::before {
    background: transparent;
    border: 1px solid gray;
}

.ui.transparent.icon.input > i.icon,
.ui.transparent.loadingWhite.icon.input > i.icon{
    width: 2.1em;
}

.ui.loading.loading.loadingWhite.input > i.icon:after {
    border-color: #ffffff transparent transparent;
}

.align-baseline{
    vertical-align: baseline !important;
}

.menuItem {
    display: inline-block;
    margin: 0 14px;
    padding-bottom: 1em;
    border-bottom: 5px solid transparent;
    transition: all 0.3s;
}

.menuItem:hover {
    border-bottom: 5px solid #F7942D;
}

.menuItem:last-child {
    padding-right: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.blackRadient:before {
    background: rgba(0,0,0,1);
    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(31,31,31,0.91) 9%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(9%, rgba(31,31,31,0.91)), color-stop(100%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(31,31,31,0.91) 9%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(31,31,31,0.91) 9%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(31,31,31,0.91) 9%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(31,31,31,0.91) 9%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.primaryGradient:before {
    background: #0d305eb0;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.pulsate-fwd {
	-webkit-animation: pulsate-fwd 0.4s ease-in-out both;
	        animation: pulsate-fwd 0.4s ease-in-out both;
}

.pulsate-bck {
	-webkit-animation: pulsate-bck 0.4s ease-in-out both;
	        animation: pulsate-bck 0.4s ease-in-out both;
}

.braintree-hosted-field {
    height: 35px;
    width: 100%;
}
.promoDescription {
    color: #fff;
}

.promoDescription h3,
.promoDescription h2,
.promoDescription h1,
.promoDescription h4,
.promoDescription h5,
.promoDescription h6,
.promoDescription p{
    margin: 0;
}

.mx-auto-important{
    margin-left: auto !important;
    margin-right: auto !important;
}

.p-0-important{
    padding: 0 !important;
}

.mt-60-important{
    margin-top: 15em !important;
}

.promoDescription h1 {
    font-size: 4em;
}

.promoDescription h2 {
    font-size: 3.5em;
}

.promoDescription h3 {
    font-size: 3em;
}

.promoDescription h4 {
    font-size: 2.5em;
}

.promoDescription h5 {
    font-size: 2em;
}

.promoDescription h6 {
    font-size: 1em;
}

.promoDescription p {
    font-size: 1.5em;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    background-color: #fff;
    color: #000;
}

.suggestions li {
    padding: 0.3rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #fff;
    color: #000;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li {
    text-align: left;
    font-size: 10px;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.ui.checkbox .box, .ui.checkbox label a.white.textDecoration.small {
    font-size: 1.1em !important;
}

.ui.search.dropdown .menu {
    max-height: 17rem !important;
}

.ui.selection.active.dropdown .menu {
    border: none !important;
    box-shadow: 0 0 20px #00000008 !important;
}

.bgLogo::before {
    background-image: url('/img/cub_logo.svg');
    background-repeat: no-repeat;
    width: 351px;
    height: 337px;
    content: '';
    position: absolute;
    display: none;
}

.accountVerticalMenu a{
    font-size: 1rem;
}

.accountVerticalMenu {
    box-shadow: 0 0 20px #00000008 !important;
    border: none !important;
    border-radius: 0 !important;
}

.accountVerticalMenu.ui.vertical.menu a.active.item {
    background: #002F5F;
    color: #fff;
}

.loginBg img.ui.tiny.centered.image {
    width: 65px;
}

.ui.secondary.inverted.menu a.item:hover {
    background: transparent !important;
}

.homeLogoWelcome {
    width: 96px;
    margin-bottom: 20px
}

.benefits .large {
    font-size: 12px !important;
}

.tableCenter {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.beforeArrowLeft,
.beforeArrowRight {
    z-index: 0;
}

.beforeArrowLeft::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 15px solid #6892be;
    position: absolute;
    top: 0;
    left: -7px;
    z-index: -1;
}

.beforeArrowRight::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid #002F5F;
    position: absolute;
    top: 0;
    right: -7px;
    z-index: -1;
}

.btnTicketWrapper {
    position: fixed !important;
    right: 44px;
    z-index: 99;
    top: 135px;
}

.hoverTicketList {
    width: 260px;
    position: absolute;
    right: -18px;
    margin-top: 15px;
    max-height: 350px;
}

.baseline {
    align-items: baseline;
}

.hide-mobile {
    display: none !important;
}

.hoverTicketList::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 10px solid #002F5F;
    border-right: 7px solid #aabfd400;
    border-left: 7px solid #fff0;
    position: absolute;
    top: -10px;
    right: 22px;
    z-index: -1;
}

.backgroundCover {
    background-image: url('/img/bg_alc.jpg');
    background-position: 66%;
    background-size: cover;
    height: 100vh !important;
}

.nonAlcbackgroundCover {
    background-image: url('/img/bg_nonalc.jpg');
    background-position: 66%;
    background-size: cover;
    height: 100vh !important;
}

.bgSecondaryLower.ui.fluid.search .results {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.homeHeaderWelcome {
    font-size: 1.6em !important;
}

.fileDrop,
.filterModalContent {
    flex-direction: column;
    display: flex;
}

.hoverOrange:hover {
    color: #F7942D !important;
    transition: all 0.3s;
}

.flexDirectionColumn {
    flex-direction: column;
}

.fontWeightRegular {
    font-weight: 400 !important;
}

.preview {
    height: 73px;
    border: 2px solid #F28E1C80;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 70px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.file-preview {
    width: 100%;
    font-weight: 700;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
    overflow: hidden;
    height: 30px;
    white-space: nowrap;
}

.deletePreview {
    position: absolute;
    right: 20px;
    top: 23px;
}

.inlineFlex {
    display: inline-flex;
}

.ui.button.hoverDarkenYellowBg,
.hoverDarkenYellowBg,
.hoverYellowBg,
.ui.button.hoverYellowBg {
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.ui.button.hoverDarkenYellowBg:after,
.hoverDarkenYellowBg:after,
.hoverYellowBg:after,
.ui.button.hoverYellowBg:after {
    position: absolute;
    content: '';
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #bd6f17;
    z-index: -1;
    transition: all .3s ease-in-out;
}

.hoverYellowBg:after,
.ui.button.hoverYellowBg:after {
    background-color: #F28E1C;
}

.ui.button.hoverDarkenYellowBg:hover:after,
.hoverDarkenYellowBg:hover:after,
.hoverYellowBg:hover:after,
.ui.button.hoverYellowBg:hover:after {
    left: 0;
}

.hoverYellowBg:hover,
.ui.button.hoverYellowBg:hover {
    color: #fff !important;
}

.landingInput input {
    border-radius: 0 !important;
}

.landingInput input,
.ui.search.dropdown.landingInput {
    background: #F5F6F7 !important;
    height: 54px;
    border: none !important;
    font-size: 16px;
    padding: 20px !important;
}

.ui.dropdown.landingInput:not(.button) > .default.text {
    color: gray !important;
}

.landingInput > .dropdown.icon {
    top: 15px !important;
}

.landing .slick-dots {
    text-align: left;
}

.landing .slick-track:focus  {
    outline: none;
}

.onlyDesktop {
    display: none;
}

.productSlider .slick-dots,
.category .slick-dots {
    position: absolute;
    top: -35px;
    right: 7px;
}

.productFeatureSlider .slick-dots{
    margin-top: 0;
}

.productSlider.brand .slick-dots,
.productFeatureSlider.brand .slick-dots {
    top: auto;
    right: 0;
    left: 0;
}

.productSlider .slick-dots button::before,
.productSlider .slick-dots .slick-active button::before,
.productFeatureSlider .slick-dots button::before,
.productFeatureSlider .slick-dots .slick-active button::before,
.category .slick-dots button::before,
.category .slick-dots .slick-active button::before {
    content: '';
}

.productSlider .slick-dots li,
.productFeatureSlider .slick-dots li,
.category .slick-dots li {
    width: 20px;
    text-align: center;
}

.productSlider .slick-dots button,
.productFeatureSlider .slick-dots button,
.category .slick-dots button {
    background: #002E65;
    border-radius: 15px;
    width: 8px;
    height: 8px;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    margin: auto;
}

.productSlider .slick-dots .slick-active button,
.productFeatureSlider .slick-dots .slick-active button,
.category .slick-dots .slick-active button {
    opacity: 1;
    width: 20px;
}

.brandListDesktop {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -3px !important;
    margin-right: 3px !important;
}

.brandListDesktop .brandItem {
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px;
}

.accordBrand .title .dropdown.icon {
    float: right !important;
}

.accordBrand .title {
    font-size: 21px !important;
    font-weight: bold !important;
    background-color: #d5d9da;
    padding-left: 1rem !important;
}

.marginMinus {
    margin-left: -1rem;
    margin-right: -1rem;
}

.accordBrand .content .link {
    display: block;
    color: #000;
    font-size: 16px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d5d9da;
    padding-left: 1rem !important;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-8-30 10:9:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@media only screen and (min-width: 1024px) {
    .lg\:pt-48 {
        padding-top: 12rem !important;
    }
    .lg\:pr-16 {
        padding-right: 4rem !important;
    }
    .testimonial-slider .slick-next {
        right: 0rem;
    }

    .testimonial-slider .slick-prev {
        right: 5.5rem;
    }
    .testimonial-slider .slick-arrow::before {
        width: 4rem;
        height: 4rem;
    }
    .testimonial-slider .slick-list {
        margin: 0 -1.5rem;
    }

    .testimonial-slider .slick-slide>div {
        padding: 0 1.5rem;
    }

    /* .welcome-border {
        border: 40px #00295D solid;
        border-top: none;
    }
    
    .testimonial-slider::after {
        content: '';
        width: 40px;
        height: 100%;
        display: block;
        position: absolute;
        right: calc(50% - 50vw);
        top: 0;
        background-color: #00234D;
    } */
}

@media only screen and (min-width: 1199px) {
    .noLargeScreen {
        display: none !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 769px) {
    .noDisplayMobile {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) {
    .marginTop30pxNoMobile {
        margin-top: 30px !important; 
    }
    .fileDrop {
        flex-direction: row;
    }

    .preview {
        width: calc(60% - 15px);
    }

    .dropzone {
        margin-bottom: 0;
        margin-right: 15px;
        width: 40%;
    }

    .gridHome {
        top: -300px;
    }

    .small,
    .small > input {
        font-size: 12px !important;
    }

    .menu {
        font-size: 14px !important;
    }

    .regular {
        font-size: 14px !important;
    }

    .large {
        font-size: 16px !important;
    }

    .largeMedium {
        font-size: 18px !important;
    }

    .big {
        font-size: 21px !important;
    }

    .huge {
        font-size: 24px !important;
    }
    .hide-mobile {
        display: initial !important;
    }
    .md\:pb-32 {
        padding-bottom: 8rem !important;
    }
    .md\:mt-0 {
        margin-top: 0 !important;
    }
    .md\:py-6{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
}

@media only screen and (min-width: 992px) {
    .mobileMenu {
        display: none;
    }

    .onlyDesktop {
        display: block;
    }

    .desktopAbsolute {
        position: absolute;
    }

    .hideComputer {
        display: none;
    }

    .ui.black.inverted.header.maxWidth230.center.marginTop10.marginBottom20.headerLarge {
        font-size: 1.6em;
    }

    .bgLogo::before {
        display: block;
        top: 0;
        right: 0px;
    }

    .mobilemenu-open {
        overflow: auto;
    }

    .blackBackground {
        display: none
    }

    .homeLogoWelcome {
        width: 145px;
    }
    
    .homeHeaderWelcome {
        font-size: 2.6em !important;
    }

    .benefits .large {
        font-size: 14px !important;
    }

    .wrapperContainer {
        min-height: calc(100vh - 373px);
    }
    .myFooter {
        /*height: 325px;*/
    }
}

.home-icon-title{
    font-size: 21px;
    color: #F2711C;
}

.home-icon-subtitle{
    font-size: 21px;
    color: #FFFFFF;
}

.home-heading.column{
    padding-left:0 !important;
    padding-right:0 !important;
}

.ui.padded.center.grid:not(.vertically):not(.horizontally) {
    margin: 0 auto !important;
}

@media only screen and (max-width: 451px) {
    .faq .ui.text.menu .item {
        padding: 15px 15px;
        border: 2px solid transparent;
    }

    .productSlider .slick-dots,
    .category .slick-dots {
        position: relative;
        top: 0;
        right: 0;
        margin-top: 0;
    }
    
    .centerMobile {
        text-align: center;
    }
    .home-icon-title{
        font-size: 12px;
    }

    .noMobile {
        display: none !important;
    }

    .fontSize16px {
        font-size: 14px !important;
    }

    .home-icon-subtitle {
        font-size: 12px;
    }

    .miniFont {
        font-size: 7px !important;
    }

    .bottom50 {
        bottom: 12px;
    }

    .ui.black.inverted.header.maxWidth230.center.marginTop10.marginBottom20.headerLarge {
        font-size: 1.3em !important;
    }

    .loginWelcome .sub.header.floatRight {
        float: none;
        position: relative;
        left: -15px;
    }
}

@media only screen and (max-width: 1201px){
    .cartLine .productImage {
        height: 50px !important;
    }
}

@media only screen and (max-width: 991px) and (min-width: 100px) {
    .sticky .headerBg {
        overflow: hidden !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 100px) {
    .welcome-container{
        max-width: 100vw !important;
    }

    .slidingMobile {
        position: absolute !important;
        top: 0;
        background: #fff;
        right: 100%;
        transition: 0.2s all;
        z-index: 1;
    }
    .slidingMobile.active {
        right: 0;
    }

    .slidingMobile.active:before {
        content: '';
        background: white;
        min-width: 100%;
        min-height: 100%;
        display: block;
        position: fixed;
    }

    .ui.checkbox.myCheckbox label {
        font-size: 14px;
    }
    .noTabletNoMobile {
        display: none;
    }
}

@media only screen and (max-height: 652px) and (min-height: 100px) {
    .homeHeaderWelcome {
        font-size: 14px !important;
    }

    .landing .slick-dots {
        margin-top: 0;
        margin-bottom: 0;
    }

    .width100px {
        width: 50px !important;
    }

    .mobileHeightFix {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        bottom: 10px;
    }
    .mobileSmallText {
        font-size: 1.5rem !important;
    }
}

@media only screen and (max-height: 767px) and (min-height: 100px) {
    .mobileMaxHeight200px {
        max-height: calc(100vh - 265px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 15px;
    }

    .minHeight466px {
        min-height: auto;
    }

    .largeMedium {
        font-size: 14px !important;
    }
    .ui.grid > .row > [class*="middle aligned"].column.stepMobile {
        /* margin-top: 70px !important; */
        align-self: baseline !important;
    }
}

@media only screen and (max-width: 320px) {
    .home-icon-title{
        font-size: 9px;
    }
    .home-icon-subtitle {
        font-size: 9px;
    }
    .miniFont {
        padding-right: 25px;
    }
    
}

@media only screen and (max-width: 451px) {
    .crazyFilter.ui.dropdown:not(.button) > .default.text,
    .crazyFilter.ui.dropdown > .text {
        font-size: 14px;
    }
    .ui.crazyFilter {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.no-border {
    border: 0 none !important;
    box-shadow: none;
}

.to-right {
    margin-left: auto;
}

